<template>
  <div class="jumbotron p-5 rounded-lg" style="margin-bottom: 2em">
    <h1>Richland Township Facilities</h1>
    <h4>For more information check out <a href="http://www.richlandtownship.com/" target="_blank" style="color: white; text-decoration: underline">Richland Township</a>
    </h4>
  </div>

  <div class="container-fluid bg-3">
    <div class="row">
      <div class="col-sm-3">
        <a href="http://www.richlandtownship.com/" target="_blank">
          <i class="fas fa-tree fa-5x" aria-hidden="true">
          </i></a>
        <h4>Richland Township</h4>
        <p>1180 N Hemlock Rd
          <br>Hemlock MI 48626
          <br><a href="tel:+1-989-642-2097">(989) 642-2097</a>
        </p>
      </div>
      <div class="col-sm-3">
        <a href="http://www.saginawcounty.com/" target="_blank">
          <i class="fas fa-university fa-5x" aria-hidden="true">
          </i></a>
        <h4>Saginaw County</h4>
        <p>111 South Michigan Ave
          <br>Saginaw, MI 48602
          <br><a href="tel:+1-989-790-5200">(989) 790-5200</a>
        </p>
      </div>
      <div class="col-sm-3">
        <a href="http://www.hemlock.k12.mi.us/" target="_blank">
          <i class="fas fa-graduation-cap fa-5x" aria-hidden="true"></i></a>
        <h4>Hemlock Public Schools</h4>
        <p>200 Wilson St.
          <br>Hemlock, MI 48626
          <br><a href="tel:+1-989-642-5282">(989) 642-5282</a>
        </p>
      </div>
      <div class="col-sm-3">
        <a href="http://www.stpeterhemlock.org/" target="_blank">
          <i class="fas fa-paw fa-5x" aria-hidden="true"></i>
        </a>
        <h4>St. Peter Lutheran</h4>
        <p>2440 N Raucholz Rd
          <br>Hemlock MI 48626
          <br>[Church] <a href="tel:+1-989-642-8188">(989) 642-8188</a>
          <br>[School] <a href="tel:+1-989-642-5659">(989) 642-5659</a>
        </p>
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-sm-3">
        <a href="">
          <i class="fas fa-shopping-basket fa-5x" aria-hidden="true"></i>
        </a>
        <h4>Local Events</h4>
        <p>
          - <a href="https://www.facebook.com/HemlockFarmersMarket" target="_blank">Farmer's Market</a>
          [<i>June-October</i>]
          <br>
          - <a href="http://www.hemlocksawdustdays.com/" target="_blank">Sawdust Days</a>
          [<i>Third Weekend of September</i>]
          <br>
        </p>
      </div>
      <div class="col-sm-3">
        <a href="https://www.hemlockbusinessassociation.com/" target="_blank">
          <i class="fas fa-users fa-5x" aria-hidden="true"></i>
        </a>
        <h4>Hemlock Business Association</h4>
        <p>Group of local business representatives working to give back to the Hemlock commmunity
        </p>
      </div>
      <div class="col-sm-3">
        <a href="https://www.saginawcounty.com/departments/commission-on-aging/saginaw-county-senior-centers/" target="_blank">
          <i class="fas fa-house-user fa-5x" aria-hidden="true"></i>
        </a>
        <h4>Senior Center</h4>
        <p>218 S Elm St
          <br>Hemlock MI 48626
          <br><a href="tel:+1-989-642-2610">(989) 642-2610</a>
        </p>
      </div>
      <div class="col-sm-3">
        <a href="#">
          <i class="fas fa-map-pin fa-5x" aria-hidden="true"></i>
        </a>
        <h4>Local Churches</h4>
        <p>
          -<a href="http://zionhemlock.org/" target="_blank">
          Zion Lutheran</a>
          [<a href="https://www.facebook.com/www.zionhemlock.org/" target="_blank">God's Helping Hands</a>]
          <br>
          -<a href="https://www.the-well-church.com/" target="_blank">
          The Well Community Church
        </a>
          <br>
          -<a href="http://stjohn23.net/" target="_blank">
          St. John XXIII Parish
        </a>
        </p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'LocalFacilities'
}
</script>

<style scoped>
.jumbotron {
  /*background-color: #4e6738;*/
  background-color: #7a9c4e;
  color: #FFFFFF;
  text-align: center;
}

.container-fluid {
  text-align: center;
}

a:link {
  text-decoration: none;
}

.fa-map-pin {

}

</style>
